<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">标题:</span>
        <el-input v-model="search.title" class="width_200" placeholder="请输入标题" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">番号:</span>
        <el-input v-model="search.fanhao" class="width_200" placeholder="请输入番号" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">账号:</span>
        <el-input v-model="search.account" class="width_200" placeholder="请输入账号" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">分类:</span>
        <select class="width_150" v-model="search.type_id" filterable placeholder="全部" size="medium" clearable>
          <option key="-1" label="全部" value="-1"></option>
          <option key="0" label="未分类" value="0"></option>
          <option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value"></option>
        </select>
      </div>
      <div class="search-box">
        <span class="search-box-title">发布:</span>
        <select class="width_80" v-model="search.is_release" filterable placeholder="全部" size="medium" clearable>
          <option v-for="item in releaseArr" :key="item.value" :label="item.label" :value="item.value"></option>
        </select>
      </div>
      <div class="search-box">
        <span class="search-box-title">免费:</span>
        <select  class="width_80" v-model="search.is_free" placeholder="全部" size="medium">
          <option label="全部" value="-1"></option>
          <option label="收费" value="0"></option>
          <option label="免费" value="1"></option>
        </select>
      </div>
      <div class="search-box">
        <span class="search-box-title">封面/缩略图:</span>
        <select  class="width_80" v-model="search.has_img" placeholder="全部" size="medium">
          <option label="全部" value="-1"></option>
          <option label="无封面" value="0"></option>
          <option label="有封面" value="1"></option>
          <option label="无缩略" value="2"></option>
        </select>
      </div>
      <div class="search-box">
        <span class="search-box-title">标签:</span>
        <select  class="width_80" v-model="search.has_tag" placeholder="全部" size="medium">
          <option label="全部" value="-1"></option>
          <option label="无" value="0"></option>
          <option label="有" value="1"></option>
        </select>
      </div>
      <div class="search-box">
        <span class="search-box-title">时间：</span>
        <el-date-picker v-model="search.searchDate" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
          value-format="YYYY-MM-DD"></el-date-picker>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
        <el-button type="primary" size="medium" @click="showFufei()">付费设置</el-button>
        <el-button type="primary" size="medium" @click="multiDel()">删除</el-button>
        <el-button type="primary" size="medium" @click="multiPublish(1)">发布</el-button>
        <el-button type="primary" size="medium" @click="multiPublish(0)">下架</el-button>
        <el-button type="primary" size="medium" @click="changeAccount()">设置视频归属</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="40px" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="id" width="70px" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="account" label="账号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="nickname" label="昵称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="fanhao" label="番号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="banner_" label="封面/缩略图" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span style="color: green" v-if="scope.row.banner_ != ''">有</span>
          <span style="color: red" v-else>无</span>
          /<span style="color: green" v-if="scope.row.thumbnail != ''">有</span>
          <span style="color: red" v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column prop="title" width="250px" label="视频标题" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="m3u8" label="分辨率" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <template v-for="(item,index) of scope.row.m3u8"  :key="index">
            <span  v-if="item !== ''">{{index}},</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="分类" width="80px" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="edition_names" label="专题" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="tag_name" label="标签" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          {{getTagName(scope.row)}}
        </template>
      </el-table-column>
      <el-table-column prop="add_time" width="110px" label="添加时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="is_release" label="状态" align="center" :show-overflow-tooltip="true">
        <template v-slot="scope">
          <span class="operation" style="text-decoration: underline" :style="{color: scope.row.is_release == '1' ? '#33C933' : '#F33333' }" @click="useVideo(scope.row)">{{scope.row.is_release == 1 ? "已发布" : "未发布"}}</span>(<span :style="{color: scope.row.is_fee == '1' ? '#33C933' : '#F33333' }">{{scope.row.is_fee == 1 ? "免费" : "付费"}})</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="170px" align="center">
        <template #default="scope">
<!--          <span class="operation" @click="getInEdition(scope.row,1)">添加精选</span>-->
          <span class="operation" @click="getInEdition(scope.row,0)">显示专题</span>
          <span class="operation" @click="editor(scope.row)">编辑</span>
          <span class="operation" @click="del(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />

    <el-dialog :title="title" v-model="dialogAdd" width="800px" top="5vh" :close-on-click-modal="false" @close="closeDialog">
      <el-row>
        <el-col :span="6">视频标题：</el-col>
        <el-col :span="18">
          <el-input v-model="dialogArr.title" placeholder="视频标题" size="small"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">视频账号/昵称：</el-col>
        <el-col :span="18">
          <el-input v-model="dialogArr.account" style="width:300px;" placeholder="上传账号" size="small"></el-input>{{dialogArr.nickname}}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">视频分类：</el-col>
        <el-col :span="18">
          <el-select disabled v-model="dialogArr.type_id" placeholder="无分类" style="width:100%" size="medium" multiple>
            <el-option label="未分类" :value="0"></el-option>
            <el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">专题(多个专题逗号隔开)：</el-col>
        <el-col :span="18">
          <el-input v-model="dialogArr.edition_names" placeholder="视频专题" size="small"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">分辨率：</el-col>
        <el-col :span="18">
<!--          <el-select>-->
            <template  v-for="(item,index) of dialogArr.m3u8"  :key="item">
              <el-tag v-if="item !== ''" :label="index">{{index}}</el-tag>
            </template>
<!--          </el-select>-->
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">番号：</el-col>
        <el-col :span="18">
          <el-input v-model="dialogArr.fanhao" placeholder="视频番号" size="small"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">标签：</el-col>
        <el-col :span="18">
          <el-select v-model="dialogArr.tag_ids" allow-create filterable placeholder="无标签" style="width:100%" multiple >
            <el-option label="无标签" :value="''"></el-option>
            <el-option v-for="item in tagList" :key="item.value" :label="item.title" :value="item.id+''"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="title !== '添加视频'">
        <el-col :span="6">观看次数：</el-col>
        <el-col :span="18">
          <el-input v-model="dialogArr.watch_count" placeholder="观看次数" size="small" type="number"></el-input>
        </el-col>
      </el-row>
      <el-row v-if="title !== '添加视频'">
        <el-col :span="6">点赞次数：</el-col>
        <el-col :span="18">
          <el-input v-model="dialogArr.good_count" placeholder="点赞次数" size="small" type="number"></el-input>
        </el-col>
      </el-row>
      <el-row v-if="title !== '添加视频'">
        <el-col :span="6">是否发布：</el-col>
        <el-col :span="18">
          <el-switch class="switch" :active-value="1" :inactive-value="2" v-model="dialogArr.is_release"></el-switch>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">视频封面图：</el-col>
        <el-col :span="18">
          <el-upload class="avatar-uploader" action="#" accept="image/*" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="upload">
            <ximg :enlarge="false" :src="dialogArr.imgUrl" v-if="dialogArr.imgUrl" class="avatar" id="video_avatar" alt=""></ximg>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="note">支持扩展名:.png .jpg</div>
          </el-upload>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">视频预览图：</el-col>
        <el-col :span="18">
          <div style="width:500px; overflow:auto;height:90px;">
            <ximg :src="dialogArr.preview_image" height="90" width="16000" v-if="dialogArr.preview_image" ></ximg>
          </div>
        </el-col>
      </el-row>
        <el-row  v-if="title !== '添加视频'">
          <el-col :span="6">付费设置：</el-col>
          <el-col :span="18">
            <el-form label-width="80px" aria-label="left">
              <el-form-item label="是否收费">
                <el-switch class="switch" :active-value=0 :inactive-value=1 v-model="dialogArr.is_fee"></el-switch>
              </el-form-item>
              <el-form-item label="购买价格"  v-show="dialogArr.is_fee == 0" >
                <el-input type="number" style="width:100px" min="0" v-model="dialogArr.buy_config.price" placeholder="请输入购买金额"></el-input>
              </el-form-item>
              <el-form-item label="试看时间"  v-show="dialogArr.is_fee == 0">
                <el-time-picker value-format="HH:mm:ss" style="width: 120px" size="mini" :picker-options="time_picker_options" v-model="dialogArr.buy_config.try_btime"></el-time-picker>-
                <el-time-picker value-format="HH:mm:ss" style="width: 120px" size="mini" :picker-options="time_picker_options" v-model="dialogArr.buy_config.try_etime"></el-time-picker>
              </el-form-item>
<!--              <el-form-item label="VIP"  v-show="dialogArr.is_fee == 0">-->
<!--                <el-select v-model="dialogArr.buy_config.free_vips" multiple="multiple" clearable="clearable">-->
<!--                  <el-option v-for="item of vipList" :key="item" :label="item.label" :value="item.value"></el-option>-->
<!--                </el-select><i>收费开启时,选中的vip免费观看</i>-->
<!--              </el-form-item>-->
            </el-form>
          </el-col>
        </el-row>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="add" v-if="title == '添加视频'">确认修改</el-button>
          <el-button type="primary" @click="edit" v-else>确认修改</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 视频修改精选或者专题 -->
    <el-dialog :title="listTitle" v-model="dialoList" width="1180px" top="10vh" :close-on-click-modal="false" @close="closeDialog">
      <div style="padding-bottom:60px">
        <div class="search">
          <div class="search-box">
            <span class="search-box-title">标题：</span>
            <el-input v-model="search_edition.title" class="width_150" placeholder="请输入专题标题" size="medium" clearable></el-input>
          </div>
          <div class="search-box">
            <span class="search-box-title">分类：</span>
            <el-select v-model="search_edition.type" class="width_100" filterable placeholder="全部" size="medium" clearable>
              <el-option label="全部" :value="0"></el-option>
              <el-option v-for="item in typeArr.filter((row)=>{
                return row.status == 1
              })" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
          <div class="search-box">
            <span class="search-box-title">是否已添加：</span>
            <el-select v-model="search_edition.is_add" class="width_100" filterable placeholder="全部" size="medium" clearable>
              <el-option label="全部" :value="-1"></el-option>
              <el-option label="未添加" :value="0"></el-option>
              <el-option label="已添加" :value="1"></el-option>
            </el-select>
          </div>
          <div class="search-box">
            <span class="search-box-title">是否开启：</span>
            <el-select v-model="search_edition.enable" class="width_100" filterable placeholder="全部" size="medium" clearable>
              <el-option label="全部" :value="-1"></el-option>
              <el-option label="关闭" :value="0"></el-option>
              <el-option label="开启" :value="1"></el-option>
            </el-select>
          </div>
          <div class="search-box">
            <span class="search-box-title">是否推荐</span>
            <el-select v-model="search_edition.tuijian" class="width_100" placeholder="全部" size="medium">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="推荐" :value="1"></el-option>
            </el-select>
          </div>
          <div class="search-box">
            <el-button type="primary" size="medium" @click="videosEdition()">查询</el-button>
          </div>
        </div>
        <div >
          <el-table :data="videoEditionList" :header-cell-style="{ background: '#F7F8FA' }" height="538">
            <el-table-column prop="id" label="ID" width="100px" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="edition_type_id" label="分类" align="center" :show-overflow-tooltip="true">
              <template #default="scope">
                {{getTypeTitle(scope.row.edition_type_id)}}
              </template>
            </el-table-column>
            <el-table-column prop="tuijian" label="推荐" align="center" :show-overflow-tooltip="true">
              <template #default="scope">
                <el-switch :active-value="1" disabled v-model="scope.row.tuijian" :inactive-value="0"></el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="enable" label="状态" align="center" :show-overflow-tooltip="true">
              <template #default="scope">
                <el-switch :active-value="1" disabled v-model="scope.row.enable" :inactive-value="0"></el-switch>
              </template>
            </el-table-column>
            <el-table-column width="200px" align="center">
              <template #default="scope">
                <span class="operation" v-if="!scope.row.is_add" @click="addVideoList(scope.row)">添加</span>
                <span v-else class="operation" style="color:#ccc" @click="delEditionVideo(scope.row,scope.$index)">删除</span>
              </template>
            </el-table-column>
          </el-table>
          <page :pager="pages" @query="videosEdition()" />
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialoList">关闭</el-button>
        </span>
      </template>
    </el-dialog>


    <el-dialog
        title="批量付费设置"
        v-model="dialogFufei"
        width="700px"
        top="10vh"
        :close-on-click-modal="false"
    >
      <el-row >
        <el-col :span="4">付费设置：</el-col>
        <el-col :span="20">
          <el-form label-width="80px" aria-label="left">
            <el-form-item label="是否收费">
              <el-switch class="switch" :active-value=0 :inactive-value=1 v-model="fufeiArr.is_fee"></el-switch>
            </el-form-item>
            <el-form-item label="购买价格" v-show="fufeiArr.is_fee == 0">
              <el-input type="number" style="width:100px" min="0" v-model="fufeiArr.price" placeholder="请输入购买金额"></el-input>
            </el-form-item>
            <el-form-item label="试看时间"  v-show="fufeiArr.is_fee == 0">
              <el-time-picker value-format="HH:mm:ss" style="width: 120px" size="mini" v-model="fufeiArr.try_btime"></el-time-picker>-
              <el-time-picker value-format="HH:mm:ss" style="width: 120px" size="mini"  v-model="fufeiArr.try_etime"></el-time-picker>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20" align="center">
          <el-button type="primary" @click="dialogFufei = false">取消</el-button>
          <el-button type="primary" @click="saveFufei" >保存</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog
        title="设置视频归属"
        v-model="dialogChangeAccount"
        width="700px"
        top="10vh"
    >
          <el-form label-width="80px" aria-label="left">
            <el-form-item label="视频ID">
              {{ids.join(",")}}
            </el-form-item>
            <el-form-item label="归属账号">
              <el-input v-model="account" placeholder="请输入视频归属账号"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-button type="primary" @click="dialogChangeAccount = false">取消</el-button>
              <el-button type="primary" @click="saveChangeAccount" >设置</el-button>
            </el-form-item>
          </el-form>
    </el-dialog>

    <el-dialog
        title="部分视频付费设置失败"
        v-model="dialogFufeiTips"
        width="700px"
        top="10vh"
        :close-on-click-modal="false"
    >
      <el-row >
        <el-col :span="14">视频</el-col>
        <el-col :span="10">失败原因</el-col>
      </el-row>
      <el-row v-for="item in errList" :key="item">
        <el-col :span="14">{{item.title}}</el-col>
        <el-col :span="10">{{ item.errMsg }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="10"></el-col>
        <el-col :span="14">
          <el-button type="primary" @click="dialogFufeiTips = false">关闭</el-button>
        </el-col>
      </el-row>
    </el-dialog>


  </div>
</template>

<script>
import {
  getList,
  add,
  edit,
  del,
  use,
  getType,
} from "@/api/video/list";
import { upload } from "@/api/common/common";
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "videoList",
  components: {
    page,
  },
  data() {
    return {
      errList:[],
      dialogFufeiTips:false,
      pager: { total: 0, page: 1, rows: 10 },
      page_edition: { total: 0, page: 1, rows: 10 },
      pages: { total: 0, page: 1, rows: 10 },
      search_edition:{
        tuijian:0,
        enable:1,
        type:0,
        title:"",
        is_add:1,
      },
      search: {
        //搜索
        id: "", //id
        title: "", //标题
        account: "", //账号
        type_id: -1, //分类
        searchDate: [], //时间
        checked: 0, //是否精确搜索
        is_release: 0, //是否发布
        is_free:"-1",
        has_img:"-1",
        has_tag:"-1",
        fanhao:"",
      },
      tableData: [], //数据
      row: [], //选中的数据
      typeArr: [],
      releaseArr: [
        { label: "全部", value: 0 },
        { label: "已发布", value: 1 },
        { label: "未发布", value: 2 },
      ],
      dialogAdd: false, //添加弹窗
      title: "",
      tagList:[],
      dialogArr: {
        tag_ids:"",
        title: "", //标题
        type_id: [], //分类
        edition_names:"",
        is_fee: 0, //是否免费
        url: "", //图片地址
        imgUrl: "", //图片完整地址
        fanhao:"",// 番号
        is_release: 2, //是否发布
        watch_count: 0, //观看次数
        good_count: 0, //点赞次数
        preview_image: "", // 视频预览图
        buy_config:{
          price:0,// "购买金额"
          try_etime:"",// 时间区间选择
          try_btime:"",// 时间区间选择
          free_vips:"", // vip选择
        },
      }, //弹窗编辑数组
      time_picker_options:{
        // start:"00:00:00",
        // end:"01:10:10",
        // step: '00:02',
        selectableRange:'18:30:00 - 20:30:00',
    },
      vipList:[],
      videoEditionList:[],
      dialoList: false, // 精选 专题弹窗
      type: 0, // 0专题 1精选
      typeListArr: [], //专题 数组
      typeListArr1: [], // 精选 数组
      listArr: [], // 已有精选 专题数据
      listTitle: "", // 精选 专题弹窗标题
      video: {}, //视频数据
      fufeiArr:{
        is_fee:0,
        price:6,// "购买金额"
        try_etime:"",// 时间区间选择
        try_btime:"",// 时间区间选择
        free_vips:"", // vip选择
      },
      dialogFufei: false,
      multipleSelection:[],
      dialogChangeAccount:false,
      account:"",
      ids:[],
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getViplist()
    this.getType();
    this.getList();
    this.getTagList()
  },
  methods: {
    getTagName(row){
      if (row.tag_ids == ""){
        return ""
      }
      var tag_name = []
      for(const id of row.tag_ids.split(",")){
        var tag = this.getTag(id)
        if (tag != ""){
          tag_name.push(tag)
        }
      }
      return tag_name.join(",")
    },
    getTag(id){
      for(var i in this.tagList){
        if (this.tagList[i].id == id){
          return this.tagList[i].title
        }
      }
      return ""
    },
    saveChangeAccount(){
      if (this.multipleSelection.length == 0){
        this.$message({ message: '请选择要设置的视频!', type: 'error' });
      }else{
        var ids = [];
        for(const video of this.multipleSelection){
          ids.push(video.id)
        }
        httpClient("setVideoAccount").post({ids:ids.join(","),account:this.account}).then((res)=>{
          if (res.code > 0){
            this.$message({message:res.msg,type:"error"})
          }else{
            this.$message({message:"设置成功",type:"success"})
            this.dialogChangeAccount = false
            this.getList()
          }
        })
      }
    },
    changeAccount(){
      if (this.multipleSelection.length == 0){
        this.$message({ message: '请选择要设置的视频!', type: 'error' });
      }else{
        this.account = ""
        var ids = [];
        for(const video of this.multipleSelection){
          ids.push(video.id)
        }
        this.ids = ids
        this.dialogChangeAccount = true
      }
    },
    multiPublish(type){
      if (this.multipleSelection.length == 0){
        this.$message({ message: '请选择要发布的视频!', type: 'error' });
      }else{
        var ids = []
        for(const row of this.multipleSelection){
          ids.push(row.id)
        }
        let param = {
          "ids":ids.join(","),
          type:type,
        }
        httpClient("multiPublish").post(param).then((res)=>{
          if(res.code == 0){
            this.getList()
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      }
    },
    getTypeTitle(id){
      for(const t of this.typeArr ){
        if (t.value == id){
          return t.label
        }
      }
      return ""
    },
    getTagList(){
      httpClient("videoTagList").post().then((res)=>{
        if(res.code == 0){
          this.tagList = res.data.list
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    multiDel(){
      if (this.multipleSelection.length == 0){
        this.$message({ message: '请选择要删除的视频!', type: 'error' });
        return;
      }
      this.$confirm("批量删除视频？此操作无法撤回,请谨慎操作", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=>{
        var ids = [];
        for(var i in this.multipleSelection){
          ids.push(this.multipleSelection[i].id)
        }
        this.$common.showLoading("批量删除视频")
        httpClient("multiDelVideo").post({
          "ids":ids.join(","),
        }).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.$message({ message: "删除成功", type: 'success' });
            this.getList()
          }else{
            this.$message({ message: res.msg, type: 'error' });
          }
        })
      })
    },
    saveFufei(){
      // 保存付费设置
      if (this.multipleSelection.length == 0){
        this.$message({ message: '请选择视频!', type: 'error' });
        return;
      }
      var ids = [];
      for(var i in this.multipleSelection){
        ids.push(this.multipleSelection[i].id)
      }
      this.$common.showLoading("批量付费设置")
      httpClient("multiFufeiVideo").post({
        "ids":ids.join(","),
        "is_fee":this.fufeiArr.is_fee,
        "price":this.fufeiArr.price,
        "try_btime":this.getTime(this.fufeiArr.try_btime),
        "try_etime":this.getTime(this.fufeiArr.try_etime),
      }).then((res)=>{
        this.$common.hideLoading()
        if (res.code == 0){
          this.dialogFufei = false
          if(res.data.errList != undefined && res.data.errList.length > 0){
            this.errList = res.data.errList
            this.dialogFufeiTips = true
          }else{
            this.$message({ message: "设置成功", type: 'success' });
          }
          this.getList()
        }else{
          this.$common.hideLoading()
          this.$message({ message: res.msg, type: 'error' });
        }
      })
    },
    showFufei(){
      if (this.multipleSelection.length == 0) {
        this.$message({ message: '请选择视频!', type: 'error' });
        return;
      }
      this.fufeiArr.try_btime = this.getTime2(0)
      this.fufeiArr.try_etime = this.getTime2(120)
      this.dialogFufei = true;
    },
    getViplist(){
      // 读取vip列表
      var _this = this
      httpClient("getVipList").post().then((res)=>{
        var vipList = []
        for(const vip of res.data){
          vipList.push({
            label:vip.vip_name,
            value:vip.id+"",
          })
        }
        _this.vipList = vipList
      })
    },
    //获取视频分类
    getType() {
      getType().then((res) => {
        if (res.code == 0) {
          this.typeArr = res.data;
        }
      });
    },
    //获取管理员数据
    getList() {
      if (!this.search.type_id && this.search.type_id !== 0) {
        this.search.type_id = -1;
      }
      this.$common.showLoading("加载中...")
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
        { key: "id", val: this.search.id },
        { key: "title", val: this.search.title },
        { key: "account", val: this.search.account },
        { key: "type_id", val: this.search.type_id },
        { key: "has_img", val: this.search.has_img },
        { key: "has_tag", val: this.search.has_tag },
        { key: "fanhao", val: this.search.fanhao },
        {
          key: "begin_time",
          val:
            this.search.searchDate.length > 0
              ? this.search.searchDate[0] + " 00:00:00" || ""
              : "",
        },
        {
          key: "end_time",
          val:
            this.search.searchDate.length > 0
              ? this.search.searchDate[1] + " 23:59:59" || ""
              : "",
        },
        { key: "checked", val: this.search.checked || 0 },
        { key: "is_release", val: this.search.is_release || 0 },
        { key: "is_free", val: this.search.is_free },
      ])
        .then((res) => {
          this.$common.hideLoading()
          if (res.code == 0) {
            if(this.pager.page == 1){
              this.pager.total = res.data.total;
            }
            this.tableData = res.data.list;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //添加视频
    add() {
      add([
        { key: "title", val: this.dialogArr.title },
        { key: "type_id", val: this.dialogArr.type_id },
        { key: "switch", val: this.dialogArr.is_fee },
        { key: "url", val: this.dialogArr.url || "pic/default.png" },
        // { key: "vUrl", val: this.dialogArr.vUrl },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.msg, type: "success" });
            this.dialogAdd = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //把数据放入编辑中
    editor(row) {
      console.log(row)
      let typeId = row.type_id.split(",");
      let arr = [];
      for (let i = 0; i < typeId.length; i++) {
        arr.push(Number(typeId[i]));
      }
      var buy_config =  {
        price:0,
        try_btime:0,
        try_etime:0,
        free_vips:""
      }
      if (row.buy_config != ""){
        buy_config = JSON.parse(row.buy_config)
        if(buy_config.price === undefined){
          buy_config.price = 0
        }
        if(buy_config.try_btime === undefined){
          buy_config.try_btime = 0
        }
        if(buy_config.try_etime === undefined){
          buy_config.try_etime = 0
        }
        if(buy_config.free_vips === undefined){
          buy_config.free_vips = ""
        }
      }
      // if (buy_config.price === undefined || buy_config.price === 0){
      //   buy_config.price = 6
      // }
      // if (buy_config.try_etime === undefined || buy_config.try_etime === 0){
      //   buy_config.try_btime = 0
      //   buy_config.try_etime = 120
      // }
      this.row = row;
      this.title = `编辑视频(ID：${row.id})`;
      this.dialogArr = {
        account:row.account,
        nickname:row.nickname,
        title: row.title, //标题
        type_id: arr, //分类
        edition_names:row.edition_names,
        tag_ids: row.tag_ids.split(","),
        is_fee: row.is_fee, //是否免费
        url: row.banner_, //图片地址
        thumbnail: row.thumbnail, //图片地址
        imgUrl: row.banner, //图片完整地址
        preview_image: row.preview_image, //图片完整地址
        m3u8:row.m3u8,
        fanhao:row.fanhao,
        is_release: row.is_release == 0 ? 2 : 1, //是否发布
        watch_count: row.watch_count, //观看次数
        good_count: row.good_count, //点赞次数
        buy_config:{
          price:buy_config.price,
          try_btime:this.getTime2(buy_config.try_btime),
          try_etime:this.getTime2(buy_config.try_etime),
          free_vips:"",
        }
      };
      this.dialogAdd = true;
    },
    //编辑视频
    edit() {
      if (this.dialogArr.watch_count < 0 || this.dialogArr.good_count < 0) {
        return this.$message({
          message: "点赞次数和观看次数不能为负数",
          type: "error",
        });
      }
      this.$common.showLoading("编辑视频")
      edit([
        { key: "id", val: this.row.id },
        { key: "title", val: this.dialogArr.title },
        { key: "switch", val: this.dialogArr.is_fee },
        { key: "account", val: this.dialogArr.account },
        { key: "edition_names", val: this.dialogArr.edition_names },
        { key: "url", val: this.dialogArr.url },
        { key: "thumbnail", val: this.dialogArr.thumbnail },
        { key: "is_release", val: this.dialogArr.is_release == 2 ? 0 : 1 },
        { key: "watch_count", val: this.dialogArr.watch_count },
        { key: "good_count", val: this.dialogArr.good_count },
        { key: "fanhao", val: this.dialogArr.fanhao },
        { key: "buy_config_price", val: this.dialogArr.buy_config.price},
        { key: "buy_config_btime", val: this.getTime(this.dialogArr.buy_config.try_btime)},
        { key: "buy_config_etime", val: this.getTime(this.dialogArr.buy_config.try_etime)},
        { key: "buy_config_vips", val: ""},
        { key: "tag_ids", val: this.dialogArr.tag_ids.join(",") || ""},
      ])
        .then((res) => {
          this.$common.hideLoading()
          if (res.code == 0) {
            if(res.data.is_new_tag){
              this.getTagList()
            }
            this.getList();
            this.$message({ message: "修改成功", type: "success" });
            this.dialogAdd = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          this.$common.hideLoading()
          console.log(err);
        });
    },
    getTime(t){
      if(t == "" || t == null){
        return 0
      }
      var rows = t.split(":")
      if (rows.length != 3){
        return 0
      }
      return parseInt(rows[0]) * 3600 + parseInt(rows[1]) * 60 + parseInt(rows[2])
    },
    getTime2(t){
      var h = Math.floor(t / 3600)
      var m = Math.floor((t - (h*3600)) / 60)
      var s = t - (h*3600+m*60)
      return h+":"+m+":"+s
    },
    // 发布视频
    useVideo(row) {
      this.row = row;
      use([{ key: "id", val: this.row.id }])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.data, type: "success" });
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //删除视频
    del(row) {
      this.row = row;
      this.$confirm(
        "你确定要删除视频：" + row.title + "吗？此操作无法撤回,请谨慎操作",
        "提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
        .then(() => {
          del([{ key: "id", val: this.row.id }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.data, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //关闭弹窗后清空
    closeDialog() {
      this.dialogArr = {
        title: "", //标题
        type_id: "", //分类
        is_fee: 0, //是否免费
        is_release: 2, //是否发布
        url: "", //图片地址
        imgUrl: "", //图片完整地址
        // vUrl: "", //视频地址
        videoUrl: "",
        buy_config:{
          price:0,
          try_btime:0,
          try_etime:0,
          free_vips:"",
        }
      }; //清空添加修改弹窗数据
      this.row = []; //清空选中数据
    },
    //上传图片
    upload(param) {
      this.$common.showLoading("上传中请稍后!")
      this.createImg(param.file,(file)=>{
        upload({ file: file, name: "videos",isencode:"true" })
          .then((res) => {
            this.$common.hideLoading()
            if (res.code == 0) {
              this.$message({ message: res.msg, type: "success" });
              this.dialogArr.url = res.data.filename;
              this.dialogArr.thumbnail = res.data.thumbnail;
              this.dialogArr.imgUrl = res.data.path;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })

    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || "image/png"|| "image/webp"|| "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;
      this.dialogArr.imgUrl = URL.createObjectURL(file)
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
        return false
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
        return false
      }
      return true
    },
    createImg(file,callback){
      var img = document.createElement("img")
      img.width = 375
      img.height = 211
      img.src = URL.createObjectURL(file)
      setTimeout(()=>{
        var canvas = document.createElement("canvas")
        canvas.width = 375
        canvas.height = 211
        canvas.getContext('2d').drawImage(img,0,0,canvas.width,canvas.height)
        callback(this.base64ToFile(canvas.toDataURL("image/png"),(new Date()).toTimeString() + ".png"))
      },200)
    },
    base64ToFile(urldata,filename){
      let arr = urldata.split(",");
      let mine = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]);// 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n);
      while (n--){
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia],filename,{type:mine})
    },
    //获取专题数据
    videosEdition() {
      let params = this.search_edition;
      params['id'] = this.row.id;
      params['pageid'] = this.pages.page-1;
      params['pcount'] = this.pages.pcount;
      httpClient("getInEdition").post(params).then((res)=>{
        if (res.code == 0){
          this.videoEditionList = res.data.list
          if (this.pages.page == 1){
            this.pages.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    // 获取视频已有的专题或精选并且打开弹窗
    getInEdition(row, type) {
      this.type = type;
      this.row = row;
      this.listTitle = `专题列表(视频：${row.title})`;
      this.dialoList = true;
      this.videosEdition()
    },
    delEditionVideo(row,index){
      let param = {
        video_id:this.row.id,
        id:row.id,
      }
      this.$confirm(`将视频ID:${this.row.id}从专题[${row.title}]中删除`, "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=>{
        httpClient("removeEditionVideo").post(param).then((res)=>{
          if(res.code == 0){
            this.videoEditionList.splice(index,1)
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      })

    },
    // 添加已有专题或精选
    addVideoList(row) {
      let param = {
        videoId:this.row.id,
        id:row.id,
      }
      httpClient("addEditionVideo").post(param).then((res)=>{
        if(res.code == 0){
          this.videosEdition()
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    }
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
/* 图片上传部分 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.note {
}
.el-row{margin-bottom: 5px;}
.pages {
  height: 30px;
  line-height: 30px;
  margin: 10px 0;
  float: right;
}
.selecteds {
  width: 400px;
  height: 536px;
  float: right;
  border: 1px solid #ccc;
}
.selectedsHerder {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  background-color: #eee;
  padding: 0 20px;
}
.selectedsEmpty {
  float: right;
  color: #409eff;
  cursor: pointer;
}
.el-form-item{margin-bottom: 5px;}
</style>
